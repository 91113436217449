<template>
	<div class="dashboard">
		<!-- 时间切换 -->
		<div class="dashboard-head">
			<div></div>

			<el-row>
				<div v-for="(item,index) in range" @click="activeRangeIndex = index,getEvDataCount()" class=" button "
					:class="index == activeRangeIndex?'is-active':'is-plain'" :key="index">
					{{item.title}}
				</div>
			</el-row>
		</div>
		<div class="dashboard-content">
			<div class="flex flex-btw">
				<div class="bg-white flex flex-btw radius cell-50">
					<div class="card energy-card flex" style="width: 33%;" v-for="(item,i) in  energyCard" :key="i">
						<div class="flex-center">
							<svg class="icon" style="height: 40px;width: 40px;" aria-hidden="true">
								<use :href="item.icon"></use>
							</svg>
						</div>
						<div style="padding-left: 20px;">
							<div>{{item.title}}</div>
							<div class="padding-top-sm">
								<span class="large">{{item.money}}</span>
							</div>
							<div class=" text-sm flex padding-top-sm">
								<div>同比{{setText(activeRangeIndex)}}</div>
								<div class="padding-left-sm" :class="item.tips == 1?'title-num-red':'title-num-green'">
									{{item.rate}}% <el-icon :class="item.tips == 1?'title-num-red':'title-num-green'">
										<Top v-if="item.tips == 1" />
										<Bottom v-else />
									</el-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-btw cell-50 margin-left">
					<div class="card " style="width: 32%;" v-for="(item,index) in serviceCard" :key="index">
						<div class="flex">
							<div class="flex-center">
								<svg class="icon" style="height: 40px;width: 40px;" aria-hidden="true">
									<use :href="item.icon"></use>
								</svg>
							</div>
							<div style="padding-left: 20px;">
								<div>{{item.title}}</div>
								<div class="padding-top-sm">
									<span class="large">{{item.money}}</span>
								</div>
								<div class="text-sm flex padding-top-sm">
									<div>同比{{setText(activeRangeIndex)}}</div>
									<div class="padding-left-sm"
										:class="item.tips == 1?'title-num-red':'title-num-green'">
										{{item.rate}}% <el-icon
											:class="item.tips == 1?'title-num-red':'title-num-green'">
											<Top v-if="item.tips == 1" />
											<Bottom v-else />
										</el-icon>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class=" flex-btw">
			<div class="item-card flex" v-for="(item,i) in chargeCard" :key="i">
				<div class="" :class="i == 1?'cell-35':'cell-50'">
					<div class="flex-btw cell-100">
						<div>{{item.title}}
							<span v-if=" i == 3">{{ setText('chargeCard') }}</span>
						</div>
					</div>
					<div class="padding-top" :class="i == 1?'flex ':''">
						<span class="text-bold text-xxl">{{item.num}}
							<span v-if="i == 2">%</span>
						</span>
					</div>
				</div>

				<div v-if="i == 1" class=" cell-65">
					<div class="flex padding-top cell-100">
						<div class="cell-50 text-sm text-gray">充&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;电: <span
								class="text-theme">
								{{item.charge_order_count}}单</span></div>
						<div class="cell-50 text-sm text-gray padding-left-sm">
							预&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;存:<span class="text-orange">
								{{item.pre_order_count}}单</span></div>
					</div>
					<div class="flex padding-top cell-100">
						<div class="cell-50 text-sm text-gray">会&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;员: <span
								class="text-theme">
								{{item.member_order_count}}单</span></div>
						<div class="cell-50 text-sm text-gray padding-left-sm">平台续期:<span class="text-orange">
								{{item.renewal_order_count}}单</span></div>
					</div>
				</div>
				<div class="cell-50 flex flex-end" v-if="i == 2 || i == 3">
					<el-popover placement="top-start" effect="dark" :width="400" trigger="hover">
						<template #reference>
							<el-icon class="bg-gray">
								<QuestionFilled />
							</el-icon>
						</template>
						<span v-if="i == 2">时间利用率: 充电时长 /服务时长 <br /> 服务时长:
							运营状态为上线的枪口数*{{ setText('interestRate') }}服务小时数</span>
						<span v-if=" i == 3">
							充电电量/直流枪口数
							<span v-show="activeRangeIndex == 2 || activeRangeIndex == 3 || activeRangeIndex == 4">
								<br /> 截止当前时间{{range[activeRangeIndex].title}}的快充动力条数据
							</span>
						</span>
					</el-popover>
				</div>

			</div>
		</div>
		<div class="echarts-card">

			<div class="line-echarts" id="line-echarts"> </div>

			<div class="pie-echarts">
				<div class="flex">
					<div class="money-echarts" id="money-echarts"> </div>

					<div class="money-echarts-data">
						<div class="money-echarts-right">
							<div v-for="(item,i) in moneyEchartsData" :key="i"
								class="padding-top-sm money-echarts-title">
								<div class="flex">
									<div class="flex-center">
										<div :style="{'background':item.color}" class="echarts-btn"></div>
									</div>
									<div class="padding-left-sm text-sm">{{item.name}}</div>
								</div>
								<div class="flex padding-top-sm justify-between">
									<div class="text-sm">到账金额：{{item.accont_money}}</div>
									<div class="padding-left-sm text-sm">占比：{{item.percent}}</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="echarts-card">
			<div class="line-echarts" id="line-business-echarts"> </div>

			<div class="pie-echarts">
				<div class="equipment-echarts">
					<div class="mb-2 flex flex-btw text-sm">
						<div class="equ-title">设备状态</div>
						<el-radio-group v-model="equipmentType" @change="changeEquType" class="ml-4">
							<el-radio label="" size="large">全部</el-radio>
							<el-radio label="1" size="large">快充</el-radio>
							<el-radio label="2" size="large">慢充</el-radio>
						</el-radio-group>
					</div>
					<div id="equipment-echarts"> </div>

					<div class="equipment-echarts-data">
						<div class="equipment-echarts-right flex">
							<div v-for="(item,i) in deviceEchartsData" :key="i" class="padding-top-sm"
								@click="onEquipmentFailure(item)" :class="item.name == '故障' ? 'cursor-pointer' : ''">
								<div class="equipment-echarts-title">
									<div :style="{'background':item.color}" class="echarts-btn"></div>
									<div class="text-sm flex">
										<span>设备状态：</span>
										<div style="white-space: pre-wrap;">{{ item.name }}</div>
									</div>
									<div class="flex padding-top-sm">
										<div class=" text-sm">占比：{{item.value}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 故障设备 -->
		<w-dialog ref="refEquipmentDialog" hideFooter="true" class="add-dialog" title="故障设备" width="50%"
			btnWidth="140px" top="10vh">
			<common-table ref="refEquipmentTable" :ispaging="false" :columns="equipmentColumns"
				:tableData="equipmentTable" tableHeight="300">
				<template #c_status='{row}'>
					<div class="text-red">故障</div>
				</template>
			</common-table>
		</w-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted
	} from 'vue';
	import * as echarts from 'echarts'
	import {
		Top,
		QuestionFilled,
		Bottom
	} from "@element-plus/icons-vue"

	import {
		Home,
		PowerStations

	} from '@/plugins/api.js'
	import {
		nextTick,
	} from 'vue';

	// 1 当日 2 昨日 3本周 4本月 5本年
	const range = ref([{
		title: '当日',
		id: 1
	}, {
		title: '昨日',
		id: 2
	}, {
		title: '本周',
		id: 3
	}, {
		title: '本月',
		id: 4
	}, {
		title: '本年',
		id: 5
	}])
	const activeRangeIndex = ref('0')
	const setText = (type) => {
		let text = '昨日'
		let chargeCardText = '(度/天)'
		let interestRateText = '今日';
		switch (activeRangeIndex.value) {
			case 0:
				text = '昨日'
				chargeCardText = '(度/天)'
				interestRateText = '今日';
				break;
			case 1:
				text = '前日'
				chargeCardText = '(度/天)'
				interestRateText = '昨日';
				break;
			case 2:
				text = '上周'
				chargeCardText = '(度/周)'
				interestRateText = '本周';
				break;
			case 3:
				text = '上月'
				chargeCardText = '(度/月)'
				interestRateText = '本月';
				break;
			case 4:
				text = '去年'
				chargeCardText = '(度/年)'
				interestRateText = '本年';
				break;
			default:
				break;
		}
		if (type == 'chargeCard') {
			return chargeCardText
		} else if (type == 'interestRate') {
			return interestRateText;
		} else {
			return text
		}

	}
	const energyCard = ref([{
			icon: '#icon-jinriyingshou',
			title: '今日营收',
			money: '0',
			rate: '0',
			tips: ''
		},
		{
			icon: '#icon-dianfei',
			title: '电费',
			money: '0',
			rate: '0',
			tips: ''
		},
		{
			icon: '#icon-fuwufei',
			title: '服务费',
			money: '0',
			rate: '0',
			tips: ''
		}
	])
	const serviceCard = ref([{
			icon: '#icon-yucun',
			title: '预存',
			money: '0',
			rate: '0',
			tips: ''
		},
		{
			icon: '#icon-huiyuan',
			title: '会员',
			money: '0',
			rate: '0',
			tips: ''
		},
		{
			icon: '#icon-pingtaixuqi',
			title: '平台续期',
			money: '0',
			rate: '0',
			tips: ''
		}
	])

	const chargeCard = ref([{
			title: '电量（度）',
			num: '0'
		},
		{
			title: '订单量（单）',
			num: '0'
		},
		{
			title: '时间利用率',
			num: '0'
		},
		{
			title: '快充动力',
			num: '0',
			tips: ''
		}
	])
	/**
	 * 
	 * 处理营收显示
	 * 
	 * **/
	const totalRevenue = (type) => {
		switch (type) {
			case 0:
				energyCard.value[0].title = '今日营收'
				break;
			case 1:
				energyCard.value[0].title = '昨日营收'
				break;
			case 2:
				energyCard.value[0].title = '本周营收'
				break;
			case 3:
				energyCard.value[0].title = '本月营收'
				break;
			case 4:
				energyCard.value[0].title = '本年营收'
				break;
			default:
				break;
		}
	}
	const getEvDataCount = async () => {
		totalRevenue(activeRangeIndex.value);
		let data = {
			station_range: '',
			time_range: range.value[activeRangeIndex.value].id
		}
		await Home.evDataCount(data).then(res => {
			if (res.Code == 200) {
				let Data = res.Data;
				//今日营收
				energyCard.value[0].money = Data.current_summary;
				energyCard.value[0].rate = Data.summary_current_compare_last;
				energyCard.value[0].tips = Data.summary_current_compare_last_tip;

				//电费
				energyCard.value[1].money = Data.current_ev_summary;
				energyCard.value[1].rate = Data.ev_current_compare_last;
				energyCard.value[1].tips = Data.ev_current_compare_last_tip;

				//服务费
				energyCard.value[2].money = Data.current_service_summary;
				energyCard.value[2].rate = Data.service_current_compare_last;
				energyCard.value[2].tips = Data.service_current_compare_last_tip;

				// 预存
				serviceCard.value[0].money = Data.current_recharge;
				serviceCard.value[0].rate = Data.recharge_current_compare_last;
				serviceCard.value[0].tips = Data.recharge_current_compare_last_tip;

				// 会员
				serviceCard.value[1].money = Data.current_member;
				serviceCard.value[1].rate = Data.member_current_compare_last;
				serviceCard.value[1].tips = Data.member_current_compare_last_tip;

				// 平台续期
				serviceCard.value[2].money = Data.current_renewal;
				serviceCard.value[2].rate = Data.renewal_current_compare_last;
				serviceCard.value[2].tips = Data.renewal_current_compare_last_tip;


				chargeCard.value[0].num = Data.total_power;
				chargeCard.value[1].num = Number(Data.charge_order_count) + Number(Data.pre_order_count) +
					Number(Data.member_order_count) + Number(Data.renewal_order_count);
				chargeCard.value[1].charge_order_count = Number(Data.charge_order_count);
				chargeCard.value[1].pre_order_count = Number(Data.pre_order_count);
				chargeCard.value[1].member_order_count = Data.member_order_count ? Number(Data
					.member_order_count) : 0;
				chargeCard.value[1].renewal_order_count = Data.renewal_order_count ? Number(Data
					.renewal_order_count) : 0;

				chargeCard.value[2].num = Data.time_availability;
				chargeCard.value[3].num = Data.fast_charging_capacity;

				let station_summary_list = Data.station_summary_list
				// if (station_summary_list.length != 0) {
				initRevenueEcharts(station_summary_list)
				// }
				let received_amount = Data.received_amount;
				// if (received_amount.length != 0) {
				initMoneyEcharts(received_amount)
				// }
				// let connector_status_list = Data.connector_status_list;
				// initEquipmentEcharts(connector_status_list)

				// 七天交易趋势
				initBusinessEcharts(Data.weeklyTradingTrendData);
			}
		})
	}


	// 设备状态
	function initEquipmentEcharts(list) {
		const chart2 = document.getElementById('equipment-echarts');
		chart2.removeAttribute('_echarts_instance_');
		var equipmentEcharts = echarts.init(document.getElementById('equipment-echarts'))
		let data = []
		for (let var1 in list) {
			data.push(list[var1])
		}
		let color = ["#35defb", "#f55253", "#5b70bf", "#a4de77", "#ffcc57", "#f77440"];
		let colors = {
			1: "#5b70bf",
			2: "#35defb",
			3: "#ffcc57",
			4: "#a4de77",
			5: "#f77440",
			256: "#f55253",
		};
		deviceEchartsData.value = data.map((res, i) => {
			return {
				name: res.name,
				color: colors[res.id_int],
				value: res.value,
			}
		})
		var option = {
			tooltip: {
				trigger: 'item'
			},
			color: color,
			// legend: legend,
			series: [{
				left: 200,
				type: 'pie',
				position: 'left',
				radius: ['48%', '78%'],
				center: ['18%', '50%'],
				avoidLabelOverlap: false,
				label: {
					show: false,
					position: 'center',
				},
				labelLine: {
					show: false
				},
				data: data
			}]
		};
		option && equipmentEcharts.setOption(option);
		equipmentEcharts.on('legendSelectchanged', function(params) {
			//点击故障
			if (params.name == '故障') {
				refEquipmentDialog.value.show()
				Home.faultList({
					station_range: ''
				}).then(res => {
					if (res.Code == 200 && res.Data) {
						equipmentTable.value = res.Data;
						nextTick(() => {
							refEquipmentTable.value.tableLoad()
						})
					}
				})

			}
		})
		return equipmentEcharts;
	}
	/**
	 * 
	 * 点击故障
	 * 
	 * **/
	const onEquipmentFailure = (row) => {
		if (row.name == '故障') {
			refEquipmentDialog.value.show();
			Home.faultList({
				station_range: '',
			}).then(res => {
				if (res.Code == 200 && res.Data) {
					equipmentTable.value = res.Data;
					nextTick(() => {
						refEquipmentTable.value.tableLoad();
					})
				}
			})

		}
	}
	const refEquipmentDialog = ref(null)
	const refEquipmentTable = ref(null)
	const equipmentColumns = ref([{
			prop: "s_name",
			label: "电站",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "e_name",
			label: "充电桩",
			color: "--text-color",
			showTooltip: true,
		}, {
			prop: "c_name",
			label: "充电枪",
			color: "--text-color",
			showTooltip: true,
		}, {
			prop: "c_power",
			label: "功率（KW）",
			color: "--text-color",
			showTooltip: true,
		}, {
			prop: "c_status",
			label: "状态",
			color: "--text-red-color",
			showTooltip: true,
			type: 'customRender'
		},
	])
	const equipmentTable = ref([])

	// 到账金额
	const moneyEchartsData = ref([])

	function initMoneyEcharts(data) {
		const chart3 = document.getElementById('money-echarts');
		chart3.removeAttribute('_echarts_instance_');
		var moneyEcharts = echarts.init(document.getElementById('money-echarts'))
		let values = data.map(res => {
			return {

				value: res.accont_money,
				name: res.mp_mch_name,

			}
		})
		let color = ["#9cdeec", "#ffa948", "#f55253", "#ffcc57", "#a4de77", ]
		moneyEchartsData.value = data.map((res, i) => {
			return {
				name: res.mp_mch_name,
				color: color[i],
				percent: res.percent + '%',
				accont_money: res.accont_money,
			}
		})



		var option = {
			tooltip: {
				trigger: 'item',
			},
			title: {
				text: '到账金额分析',
			},
			color: color,
			// legend: legend,
			series: [{
				top: 20,
				type: 'pie',
				left: 20,
				radius: ['40%', '70%'],
				center: ['50%', '50%'],
				avoidLabelOverlap: false,
				label: {
					show: false,
					position: 'center',
					// formatter: function (params) {
					// 	return params.value;
					// },
				},
				labelLine: {
					show: false
				},
				data: values
			}]
		};
		option && moneyEcharts.setOption(option);
	}

	//电站营收
	function initRevenueEcharts(list) {
		const chart1 = document.getElementById('line-echarts');
		chart1.removeAttribute('_echarts_instance_');
		var revenueEcharts = echarts.init(document.getElementById('line-echarts'))
		let station_ev_total = [] //电费
		let station_service_total = [] //服务费
		let station_name = []
		list.forEach(res => {
			station_ev_total.push(res.station_ev_total)
			station_service_total.push(res.station_service_total)
			station_name.push(res.s_name)
		})
		let color = ["#49cca2", "#ffde61"]
		var option = {
			title: {
				text: '电站营收排名',
			},
			legend: {
				data: ['电费', '服务费'],
				right: 10,
			},
			tooltip: {
				trigger: 'axis'
			},
			xAxis: [{
				type: 'category',
				axisLine: {
					show: true,
					lineStyle: {
						color: '#ccc'
					}
				},
				data: station_name
			}],
			yAxis: [{
				type: 'value'
			}],
			color: color,
			series: [{
					name: '电费',
					type: 'bar',
					barWidth: '20px', // 柱子的宽度
					data: station_ev_total
				},
				{
					name: '服务费',
					type: 'bar',
					barWidth: '20px', // 柱子的宽度
					data: station_service_total
				}
			]
		};
		option && revenueEcharts.setOption(option);
	}
	/**
	 * 
	 * 七天交易趋势
	 * 
	 * **/
	function initBusinessEcharts(list) {
		const chart1 = document.getElementById('line-business-echarts');
		chart1.removeAttribute('_echarts_instance_');
		var revenueEcharts = echarts.init(document.getElementById('line-business-echarts'))
		let total_order_amount = []; // 订单总额
		let charging_capacity = []; //充电电量
		let date = []; // 日期
		if (list.length > 0) {
			list.forEach(item => {
				total_order_amount.push(item.money);
				charging_capacity.push(item.total_power);
				date.push(item.day);
			})
		}
		let color = ["#41CEA5", "#A5DC79"];
		var option = {
			title: {
				text: '七天交易趋势',
			},
			legend: {
				data: ['订单总额', '充电电量'],
				x: 'right',
				y: '1%',
				itemWidth: 12,
				itemHeight: 12,
				icon: 'rect',
				textStyle: {
					color: '#000',
					fontSize: '14'
				},
			},
			tooltip: {
				trigger: 'axis',
				formatter: function(params) {
					const totalStart = '<span style="color: #25C798">';
					const totalEnd = '</span>';
					const quantityStart = '<span style="color: #A3DE76">';
					const quantityEnd = '</span>';
					return `${params[0].axisValue}
						<br/>订单总额：${totalStart}${params[0].value}${totalEnd}
						<br/>电费：${list[params[0].dataIndex].elec_money}
						<br/>服务费：${list[params[0].dataIndex].sevice_money}
						<br/>预存费：${list[params[0].dataIndex].recharge_money}
						<br/>续期费：${list[params[0].dataIndex].renewal_money}
						<br/>会员月卡：${list[params[0].dataIndex].member_money}
						<br/>充电电量：${quantityStart}${list[params[0].dataIndex].total_power}度${quantityEnd}`
				},
			},
			xAxis: [{
				type: 'category',
				boundaryGap: false,
				axisLine: {
					show: true,
					lineStyle: {
						color: '#26367A'
					}
				},
				splitArea: {
					show: false,
				},
				axisTick: {
					show: false,
				},
				splitLine: {
					show: false,
				},
				data: date,
				// boundaryGap: ['10%', '10%']
			}],
			yAxis: [{
					type: "value",
					axisTick: {
						show: false,
					},
					axisLine: {
						show: false,
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.08)',
						},
					},
				},
				{
					type: "value",
					position: "right",
					axisTick: {
						show: false,
					},
					axisLine: {
						show: false,
					},
					splitLine: {
						show: false,
					},
				},
			],
			color: color,
			series: [{
					name: "订单总额",
					type: "line",
					data: total_order_amount,
					symbolSize: 12, // 折现点大小
					symbol: "circle", // 折现点为实心
					smooth: true,
					yAxisIndex: 0,
					showSymbol: false,
					lineStyle: {
						width: 2,
						color: '#41CEA5',
					},
				},
				{
					name: "充电电量",
					type: "line",
					data: charging_capacity,
					symbolSize: 12,
					yAxisIndex: 1,
					symbol: "circle",
					smooth: true,
					showSymbol: false,
					lineStyle: {
						width: 2,
						color: '#A5DC79',
					},
					areaStyle: {
						color: {
							type: "linear",
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [{
									offset: 0,
									color: "rgba(163,219,117, 0.3)",
								},
								{
									offset: 1,
									color: "rgba(255,255,255, 0.5)",
								},
							],
							global: false, // 缺省为 false
						},
					},
				},
			]
		};
		option && revenueEcharts.setOption(option);
	}

	//切换设备状态
	const equipmentType = ref('')
	const deviceEchartsData = ref([]); // 设备状态数据
	const changeEquType = () => {
		Home.dcAcFilter({
			type: equipmentType.value
		}).then(res => {
			if (res.Code == 200) {
				initEquipmentEcharts(res.Data)
			}
		})
	}

	onMounted(() => {
		changeEquType();
		getEvDataCount();
	})
</script>

<style lang="scss" scoped>
	.icon {
		width: 100px;
		height: 80px;
		fill: currentColor;
		overflow: hidden;
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.height-40 {
		height: 40px;
	}

	.text-red {
		color: #ff0000;
	}

	.text-sm {
		font-size: 12px;
	}

	.padding-top-sm {
		padding-top: 5px;
	}

	.justify-between {
		justify-content: space-between;
	}

	.cursor-pointer {
		cursor: pointer;
	}


	.dashboard {
		height: calc(100vh - 120px);
		overflow-y: scroll;
		scrollbar-width: none;

		.echarts-card {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;

			.line-echarts {
				border-radius: 10px;
				background-color: #fff;
				width: 64%;
				height: 430px;
				padding: 10px;
			}

			.pie-echarts {
				width: 34%;
				height: 420px;

				.money-echarts {
					border-radius: 10px;
					background-color: #fff;
					height: 430px;
					width: 240px;
					padding: 10px;
				}

				.equipment-echarts-data {
					height: 200px;
					background-color: #fff;
					width: 100%;
					font-size: 14px;
					color: #444444;
					overflow: auto;

					.echarts-btn {
						height: 14px;
						width: 20px;
						border-radius: 3px;
					}
				}

				.equipment-echarts-right {
					width: 100%;
					flex-wrap: wrap;
					justify-content: flex-start;
				}

				.equipment-echarts-title {
					background-color: #F6F8F9;
					padding: 10px 5px;
					margin: 5px 5px;
					width: 160px;
					border-radius: 5px;
				}

				.money-echarts-title {
					background-color: #F6F8F9;
					padding: 10px 10px;
					margin: 5px 0;
					width: 270px;
					border-radius: 5px;
				}

				.money-echarts-right {
					display: table-cell;
					vertical-align: middle;
					height: 400px;
				}

				.money-echarts-data {
					height: 430px;
					background-color: #fff;
					width: calc(100% - 240px);
					font-size: 14px;
					color: #444444;
					padding-top: 20px;
					overflow: auto;

					.echarts-btn {
						height: 14px;
						width: 20px;
						border-radius: 3px;
					}
				}

				.equipment-echarts {
					border-radius: 10px;
					background-color: #fff;
					height: 430px;
					padding: 10px;

					.equ-title {
						font-size: 18px;
						font-weight: bold;
						color: #484848;
					}

					#equipment-echarts {
						height: 175px;
					}
				}
			}
		}

		.dashboard-head {
			background-color: #fff;
			border-radius: 10px;
			padding: 10px;
			display: flex;
			justify-content: space-between;

			.margin-left {
				margin-left: 10px;
			}

			.button {
				margin: 5px;
				text-align: center;
				padding: 5px 20px;
				border-radius: 5px;
				font-size: 14px;
				cursor: pointer;
			}

			.is-plain {
				background-color: var(--search-bg-color);
				border: none;
				color: #333;
			}

			.is-active {
				background-color: var(--theme-color);
				border: none;
				color: #fff;
			}
		}

		.dashboard-content {
			margin-top: 10px;
			border-radius: 10px;

			.title-num-green {
				color: #00d39b;
			}


			.card {
				background-size: 100% 100%;
				height: 100px;
				padding: 10px 15px;
				border-radius: 10px;
				background-color: #fff;

				.large {
					font-size: 30px;
				}

				img {
					height: 40px;
					width: 40px;
				}
			}

			.energy-card {
				position: relative;
			}

			.energy-card:nth-child(1)::after {
				position: absolute;
				content: '';
				background-color: #eee;
				height: 20px;
				width: 2px;
				top: 40px;
				right: 5px;
			}

			.energy-card:nth-child(2)::after {
				position: absolute;
				content: '';
				background-color: #eee;
				height: 20px;
				width: 2px;
				top: 40px;
				right: 5px;
			}
		}

		.item-card {
			background-color: #fff;
			padding: 15px 15px 15px 30px;
			width: 24%;
			margin-top: 10px;
			border-radius: 10px;

			.bg-gray {
				color: #9c9c9c;
				cursor: pointer;
			}

			.text-xxl {
				font-size: 32px;
			}

			.text-sm {
				font-size: 12px;
			}

			.text-theme {
				color: #00d39b;
			}

			.text-orange {
				color: #ffaa00;
			}
		}
	}

	.dashboard::-webkit-scrollbar {
		display: none;
		/* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}
</style>